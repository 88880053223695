<template>
    <v-card outlined @click="clickAppointment" :ripple="clickable" :class="clickable ? '' : 'v-card-non-clickable'">
        <v-dialog
            v-model="showAnmeldung"
            width="1000"
            persistent            
        >
            <v-card>
                <div style="display: flex; justify-content: space-between;">
                    <v-card-title ><h2>Anmeldung Übersicht</h2></v-card-title>
                    <div style="display: flex; justify-content: center; flex-flow: column;">
                        <v-btn icon  @click="showAnmeldung = false" >
                            <v-icon >mdi-close</v-icon>
                        </v-btn>
                    </div>
                </div>
                <div v-if="pdfSrc" style="margin: 5px;">
                    <iframe :src="pdfSrc" width="100%" height="1200px"></iframe>
                </div>
                <v-card-actions style="display: flex; justify-content: center;">
                    <v-btn color="primary" style="width: 200px;" @click="showAnmeldung = false">Close</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
            
        <div v-if="attendeeInfo">
            <v-alert v-if="appointment.canceled" type="success" flat dense text class="ma-1">Teilnahme nicht erforderlich</v-alert>
            <v-alert v-else-if="!appointment.closed" type="info" flat dense text class="ma-1">Teilnahme ausstehend</v-alert>
            <v-alert v-else-if="attendeeInfo.attended" type="success" flat dense text class="ma-1">Teilgenommen</v-alert>
            <v-alert v-else-if="!attendeeInfo.attended" type="error" flat dense text class="ma-1">Verpasst / nicht Teilgenommen</v-alert>
        </div>
        <v-alert v-if="appointment.registered" type="info" flat dense text class="ma-1" @click="displayAnmeldung">Angemeldet bei der Bezirksregierung </v-alert>
        <v-alert v-if="appointment.roomBooked" type="success" flat dense text class="ma-1">Raum gebucht </v-alert>
        <div v-if="$vuetify.breakpoint.name == 'xs'" class="pa-4">
            <v-row>
                <v-col cols="6">
                    <b>Ort und Datum</b>
                </v-col>
                <v-col cols="6" class="text-right">
                    <b>Modul</b>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="6">
                    {{ formatDayName(appointment.from) }}
                    <br>
                    {{ formatDate(appointment.from) }}
                    <br>
                    {{ formatTime(appointment.from) }} - {{ formatTime(appointment.to) }}
                    <br>
                    {{ appointment.address.location }}
                </v-col>
                <v-col cols="6" class="text-right">
                    <v-chip>Modul {{appointment.module}}</v-chip>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="6" class="text-left">
                    <b>Schulungsleiter</b>
                </v-col>
                <v-col cols="6" class="text-right">
                    <b>Teilnehmer & Preis</b>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="6" class="text-left">
                    {{ appointment.teacher.firstName }} {{ appointment.teacher.lastName }}
                </v-col>
                <v-col cols="6" class="text-right">
                    {{ appointment.attendees }}/{{ appointment.maxAttendees }} Teilnehmer
                    <br>
                    ~ {{ formatPrice(appointment.minPrice) }} € - {{ formatPrice(appointment.maxPrice) }} €
                    <v-tooltip left>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on">
                                <v-icon>mdi-information</v-icon>
                            </v-btn>
                        </template>
                        <span>Bei den Preisen handelt es sich um eine unverbindliche Preisschätzung, die jederzeit verändert werden kann.</span>
                    </v-tooltip>
                    <br>
                    {{ formatPrice(appointment.teachingAids) }} € Lehrmittelkosten
                </v-col>
            </v-row>
            <v-row v-if="appointment.canceled">
                <v-col cols="12">
                    <v-alert text dense color="error" type="warning" class="ma-0">Dieser Termin wurde abgesagt.</v-alert>
                </v-col>
            </v-row>
        </div>
        <div v-else class="pa-4">
            <v-row>
                <v-col cols="3">
                    <b>Ort und Datum</b>
                </v-col>
                <v-col cols="3" class="text-center">
                    <b>Modul</b>
                </v-col>
                <v-col cols="3" class="text-center">
                    <b>Schulungsleiter</b>
                </v-col>
                <v-col cols="3" class="text-right">
                    <b>Teilnehmer & Preis</b>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="3">
                    {{ formatDayName(appointment.from) }}
                    <br>
                    {{ formatDate(appointment.from) }}
                    <br>
                    {{ formatTime(appointment.from) }} - {{ formatTime(appointment.to) }}
                    <br>
                    {{ appointment.address.location }}
                </v-col>
                <v-col cols="3" class="text-center">
                    <v-chip>Modul {{appointment.module}}</v-chip>
                </v-col>
                <v-col cols="3" class="text-center">
                    {{ appointment.teacher.firstName }} {{ appointment.teacher.lastName }}
                </v-col>
                <v-col cols="3" class="text-right">
                    {{ appointment.attendeeRecommendationIDs.length }} Vorgemerkt
                    <br>
                    {{ appointment.attendees }}/{{ appointment.maxAttendees }} Teilnehmer
                    <br>
                    ~ {{ formatPrice(appointment.minPrice) }} € - {{ formatPrice(appointment.maxPrice) }} €
                    <v-tooltip left>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on">
                                <v-icon>mdi-information</v-icon>
                            </v-btn>
                        </template>
                        <span>Bei den Preisen handelt es sich um eine unverbindliche Preisschätzung, die jederzeit verändert werden kann.</span>
                    </v-tooltip>
                    <br>
                    {{ formatPrice(appointment.teachingAids) }} € Lehrmittelkosten
                </v-col>
            </v-row>
            <v-row v-if="appointment.canceled">
                <v-col cols="12">
                    <v-alert text dense color="error" type="warning" class="ma-0">Dieser Termin wurde abgesagt. In den Termininformationen finden Sie unter Umständen weitere Details.</v-alert>
                </v-col>
            </v-row>
        </div>
        <slot></slot>
    </v-card>
</template>
<script>
import { format } from "date-fns"
import jsPDF from "jspdf"
import { Buffer } from "buffer"

export default {
    data: () => ({
        showAnmeldung: false,
        pdfSrc: null
    }),
    props: {
        appointment: Object,
        clickable: {
            type: Boolean,
            default: true
        }
    },
    methods: {
        formatPrice(price) {
            return (Math.round(price * 100) / 100).toFixed(2).replace(".", ",")
        },
        clickAppointment() {
            this.$emit("click", this.appointment)
        },
        formatDayName(time) {
            const dayNames = ["Sonntag", "Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag"]
            return dayNames[time.getDay()]
        },
        formatDate(time) {
            return format(time, "dd.MM.yyyy")
        },
        formatTime(time) {
            return format(time, "HH:mm")
        },
        formatTeacher(teacher) {
            return `${teacher.firstName} ${teacher.lastName}`
        },
        async displayAnmeldung() {
            this.showAnmeldung = true
            const appointment = this.appointment
            let module1, module2, module3, module4, module5
            if (appointment.module === 1) {
                module1 = true
            } else if (appointment.module === 2) {
                module2 = true
            } else if (appointment.module === 3) {
                module3 = true
            } else if (appointment.module === 4) {
                module4 = true
            } else if (appointment.module === 5) {
                module5 = true
            }

            const pdf = `
            <div id="pdf" style="padding: 10px; width: 570px">
                <div style="display: flex;justify-content: space-between">
                    <div>
                        <h4>
                        NEW mobil und aktiv Mönchengladbach GmbH
                        <br/>
                        Odenkirchener Str. 201
                        <br/>
                        41236 Mönchengladbach
                        </h4>
                    </div>
                    <div>
                        <p style="margin-bottom: 5px; font-size: 12px">Datum :   ${this.formatDate(appointment.registeredTimestamp || appointment.from)}</p>
                        <p style="margin-bottom: 5px; font-size: 12px">Tel.:    02166 688-4619</p>
                        <p style="margin-bottom: 5px; font-size: 12px">E.Mail:  <a>dirk.offermann@new.de</a></p>
                        <p style="margin-bottom: 5px; font-size: 12px">Tel.:    02166 688-4619</p>
                        <p style="margin-bottom: 5px; font-size: 12px">Ansprechpartner:    Herr Offermann</p>
                        <p style="margin-bottom: 5px; font-size: 12px">Aktenzeichen:    25.01.14 - 02/22</p>
                        <p style="margin-bottom: 5px; font-size: 12px">E.Mail:    <a>dirk.offermann@new.de</a></p>
                    </div>
                </div>
                <div style="margin-top: 20px;">
                    <strong>
                        Anzeige gemäß § 11 Absatz 4 BKrFQG über die beabsichtigte <br/>
                        Durchführung einer Weiterbildung / einer beschleunigten Grundqualifikation
                    </strong>
                </div>
                <table border="1" class="dataframe" style="width: 100%; margin-top: 10px; font-size: 14px">
                    <tbody>
                        <tr>
                            <td style="padding: 4px;">Art</td>
                            <td style="padding: 4px;">Weiterbildung</td>
                        </tr>
                        <tr>
                            <td style="padding: 4px;">Datum der Schulung</td>
                            <td style="padding: 4px;">${this.formatDate(appointment.from)}</td>
                        </tr>
                        <tr>
                            <td style="padding: 4px;">Ort (Firma, Hotel, Fahrschule usw.)</td>
                            <td style="padding: 4px; ">
                                NEW mobil und aktiv Mönchengladbach GmbH<br/>${appointment?.address?.street} ${appointment?.address?.number}<br/>
                                ${appointment?.address?.postcode} ${appointment?.address?.location}
                            </td>
                        </tr>
                        <tr>
                            <td style="padding: 4px;">Zeit (von bis einschließlich Pausen)</td>
                            <td style="padding: 4px;">${format(appointment.from, "HH:mm")} Uhr bis ${format(appointment.to, "HH:mm")} Uhr</td>
                        </tr>
                        <tr>
                            <td style="padding: 4px;">voraussichtliche Anzahl der Teilnehmer</td>
                            <td style="padding: 4px;">${appointment.maxAttendees}</td>
                        </tr>
                        <tr>
                            <td style="padding: 4px;">Kenntnisbereich (Modul)</td>
                            <td style="padding: 4px;">Modul ${appointment.module}</td>
                        </tr>
                        <tr>
                            <td style="padding: 4px;">Kenntnisbereich (gem. BKrFQV)</td>
                            <td style="padding: 4px;">
                                1. Eco -Training & Assistenzsysteme
                                <div style="padding: 5px; display:flex; justify-content: space-between">
                                    <div>1.1. <input type="checkbox" ${module1 && "checked"}></div>
                                    <div>1.2. <input type="checkbox" ${module4 && "checked"}></div>
                                    <div>1.3. <input type="checkbox" ${module1 && "checked"}></div>
                                    <div>1.3.a <input type="checkbox" ${module1 && "checked"}></div>
                                </div>
                                <div style="margin-bottom: 5px; padding: 5px; display:flex; justify-content: space-between">
                                    <div>1.4. <input type="checkbox" ${module5 && "checked"}></div>
                                    <div>1.5. <input type="checkbox" ${module2 && "checked"}></div>
                                    <div>1.6. <input type="checkbox" ${module2 && "checked"}></div>
                                </div>
                                2. Sozialvorschriften & Fahrtenschreiber
                                <div style="margin-bottom: 5px; padding: 5px; display:flex; justify-content: space-between">
                                    <div>2.1. <input type="checkbox" ${module3 && "checked"}></div>
                                    <div>2.2. <input type="checkbox"></div>
                                    <div>2.3. <input type="checkbox" ${module3 && "checked"}></div>
                                </div>
                                3. Gefahrenwahrnehmung
                                <div style="padding: 5px; display:flex; justify-content: space-between">
                                    <div>3.1. <input type="checkbox" ${module4 && "checked"}></div>
                                    <div>3.2. <input type="checkbox" ${module4 && "checked"}></div>
                                    <div>3.3. <input type="checkbox" ${module5 && "checked"}></div>
                                    <div>3.4. <input type="checkbox" ${module5 && "checked"}></div>
                                </div>
                                <div style="padding: 5px; display:flex; justify-content: space-between">
                                    <div>3.5. <input type="checkbox" ${module4 && "checked"}></div>
                                    <div>3.6. <input type="checkbox" ${module5 && "checked"}></div>
                                    <div>3.7. <input type="checkbox" ${module5 && "checked"}></div>
                                    <div>3.8. <input type="checkbox" ${module5 && "checked"}></div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td style="padding: 4px;">verantwortlicher Unterrichtsleiter</td>
                            <td style="padding: 4px;"><strong>${this.formatTeacher(appointment.teacher)}</strong></td>
                        </tr>
                        <tr>
                            <td style="padding: 4px;">weitere Ausbilderinnen und Ausbilder</td>
                            <td style="padding: 4px;">${this.formatTeacher(appointment.teacher)== "Markus Veit" ? "Siewert, Manfred":"Veit, Markus"}<br/>Offermann, Dirk<br/>Smeets, Wolfgang</td>
                        </tr>
                    </tbody>
                </table>
                <div style="display:flex; justify-content:space-between">
                    <div style="margin-left: 10px; width: 300px;">
                        <div style="margin-top: 10px;">Mönchengladbach / ${this.formatDate(appointment.registeredTimestamp || appointment.from)}</div>
                        <hr><div style="text-align: center;">Ort / Datum</div>
                    </div>
                    <div style="margin-right: 10px; width: 200px;"><div style="margin-top: 10px; text-align: center">i.V. Reuter</div><hr><div style="text-align: center;">Unterschrift</div></div>
                </div>
            </div>
            `
            const pdfDoc = new jsPDF("p", "pt", "a4")

            await new Promise((resolve) => {
                pdfDoc.html(pdf, {
                    x: 10,
                    y: 30,
                    callback: () => {
                        resolve()
                    }
                })
            })
            const pdfBuffer = Buffer.from(pdfDoc.output("arraybuffer"))
            const blob = new Blob([pdfBuffer], { type: "application/pdf" })
            this.pdfSrc = URL.createObjectURL(blob)            
        }
    },
    computed: {
        attendeeInfo() {
            for (const attendee of this.$store.state.appointment.myAttendees) {
                if (attendee.appointmentID == this.appointment.id) {
                    return attendee
                }
            }
            return null
        }
    }
}
</script>
<style>
.v-card-non-clickable.v-card--link {
    cursor: default;
}
.v-card-non-clickable.v-card--link:before {
    background: none;
}
</style>