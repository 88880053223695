import { Address } from "./Address"
import { firestore } from "@/firebase"
import { addDoc, setDoc, collection, doc } from "firebase/firestore"

export class Appointment {
    #id = null

    from = null
    to = null
    maxAttendees = 0
    attendees = 0
    attendeeSubs = []
    attendeeDriverIDs = []
    attendeeRecommendationIDs = []
    address = new Address()
    price = 0
    teachingAids = 0
    teacher = null
    module = null
    info = null
    closed = false
    closedTimestamp = null
    canceled = false
    slots = null
    registered = null
    registeredTimestamp = null
    roomBooked = false
    
    constructor(doc = null) {
        if (doc) {
            this.#id = doc.id

            const data = doc.data()
            this.attendees = data.attendees
            this.attendeeSubs = data.attendeeSubs || []
            this.attendeeDriverIDs = data.attendeeDriverIDs || []
            this.attendeeRecommendationIDs = data.attendeeRecommendationIDs || []
            this.from = data.from.toDate()
            this.to = data.to.toDate()
            this.maxAttendees = data.maxAttendees
            this.address = new Address(data.address)
            this.price = data.price
            this.teachingAids = data.teachingAids
            this.teacher = data.teacher
            this.module = data.module
            this.info = data.info
            this.registered = data.registered || null
            this.roomBooked = data.roomBooked || false
            this.canceled = data.canceled == true
            this.closed = data.closed == true
            if (data.closedTimestamp) {
                this.closedTimestamp = data.closedTimestamp.toDate()
            }
            if (data.registeredTimestamp) {
                this.registeredTimestamp = data.registeredTimestamp.toDate()
            }
            this.slots = data.slots || null
        }
    }

    async cancelAppointment(info) {
        this.info = info
        this.canceled = true
        await this.toFirestore()
    }
    async closeAppointment() {
        this.closed = true
        this.closedTimestamp = new Date()
        await this.toFirestore()
    }
    async toFirestore() {
        if (this.#id) {
            await setDoc(this.ref, this.#toData())
        } else {
            const addedDoc = await addDoc(this.collectionRef, this.#toData())
            this.#id = addedDoc.id
        }
    }

    #toData() {
        return {
            from: this.from,
            to: this.to,
            maxAttendees: this.maxAttendees,
            attendees: this.attendees,
            address: this.address.toData(),
            price: this.price,
            teachingAids: this.teachingAids,
            teacher: this.teacher,
            module: this.module,
            info: this.info,
            canceled: this.canceled,
            closed: this.closed,
            closedTimestamp: this.closedTimestamp,
            slots: this.slots,
            registered: this.registered,
            registeredTimestamp: this.registeredTimestamp,
            roomBooked: this.roomBooked,
            attendeeRecommendationIDs: this.attendeeRecommendationIDs,
            attendeeDriverIDs: this.attendeeDriverIDs,
            attendeeSubs: this.attendeeSubs
        }
    }

    get ref() {
        return doc(this.collectionRef, this.#id)
    }
    get collectionRef() {
        return collection(firestore, "appointment")
    }
    get id() {
        return this.#id
    }
    get minPrice() {
        if (this.canceled) {
            return 0
        }
        return this.price / this.maxAttendees
    }
    get maxPrice() {
        if (this.canceled) {
            return 0
        }
        return this.attendees == 0 ? this.price : this.price / this.attendees
    }
}