import { Company } from "@/classes/Company"
import { firestore } from "@/firebase"
import { collection, doc, onSnapshot, query, where } from "firebase/firestore"
import store from "@/store"
import { Driver } from "@/classes/Driver"

const companyRef = collection(firestore, "company")

const state = {
    loading: true,
    loadingDrivers: true,
    companies: [],
    drivers: [],
    selectedCompanyID: null,
    selectedCompanyDrivers: null,
    firestoreListener: {
        companies: null,
        drivers: null
    }
}
const getters = {
    selectedCompany(state) {
        if (state.selectedCompanyID) {
            for (const company of state.companies) {
                if (company.id == state.selectedCompanyID) {
                    return company
                }
            }
        }
        return null
    }
}
const actions = {
    attachCompanies({ state, dispatch }) {
        return new Promise((resolve, reject) => {
            if (state.firestoreListener.companies) {
                state.loading = false
                resolve(state.companies)
            }
            state.loading = true
            let ownCompanyRef
            if (store.state.auth.userClaims.permissions.companies) {
                ownCompanyRef = companyRef
            } else {
                ownCompanyRef = query(companyRef, where(`dispatcher.${store.state.auth.profile.sub}.sub`, "==", store.state.auth.profile.sub))
            }
            state.firestoreListener.companies = onSnapshot(ownCompanyRef, async (snapshot) => {
                state.companies = []
                for (const companyDoc of snapshot.docs) {
                    state.companies.push(new Company(companyDoc))
                }
                if (state.companies.length >= 1 && state.selectedCompanyID == null) {
                    const selectedCompany = await dispatch("getUserData", {
                        value: "selected_company",
                        defaultValue: state.companies[0].id
                    })
                    let companyExist = false
                    for (const company of state.companies) {
                        if (company.id == selectedCompany) {
                            companyExist = true
                            break
                        }
                    }
                    if (companyExist) {
                        state.selectedCompanyID = selectedCompany
                    } else {
                        state.selectedCompanyID = state.companies[0].id
                    }
                }
                state.loading = false
                resolve(state.companies)
            }, (err) => {
                state.loading = false
                reject(err)
            })
        })
    },
    attachDrivers({ state, getters }) {
        return new Promise((resolve, reject) => {
            if (state.firestoreListener && state.selectedCompanyID == state.selectedCompanyDrivers) {
                state.loadingDrivers = false
                resolve(state.drivers)
                return
            } else {
                if (state.firestoreListener.drivers) {
                    state.firestoreListener.drivers()
                }
            }
            state.selectedCompanyDrivers = state.selectedCompanyID
            state.loadingDrivers = true
            // const isInternalCompany = state.companies.find(company => company.id == state.selectedCompanyID)?.internal
            const driversRef = collection(doc(companyRef, state.selectedCompanyID), "driver")
            state.firestoreListener.drivers = onSnapshot(driversRef, (snapshot) => {
                state.drivers = []
                if (getters.selectedCompany) {
                    for (const doc of snapshot.docs) {
                        // if (isInternalCompany) {
                        const personalID = doc.data().personalID
                        if (personalID) {
                            if ( personalID.length >= 6) {
                                state.drivers.push(new Driver(getters.selectedCompany, doc))
                            }
                        } else {
                            state.drivers.push(new Driver(getters.selectedCompany,doc))
                        }
                        // } else {
                        //     state.drivers.push(new Driver(getters.selectedCompany,doc))
                        // }
                    }
                }
                state.loadingDrivers = false
                resolve(state.drivers)
            }, (err) => {
                state.loadingDrivers = false
                reject(err)
            })
        })
    }
}
export default { state, actions, getters }
